/** @jsx jsx */
import { jsx, Container, Styled } from "theme-ui"
import { useTrail } from "react-spring"
import Layout from "./layout"
import { ChildImageSharp } from "../types"
import ProjectItem from "./project-item"

type Props = {
  projects: {
    color: string
    title: string
    slug: string
    service: string
    client: string
    cover: ChildImageSharp
  }[]
}

const Projects = ({ projects }: Props) => {
  const trail = useTrail(projects.length, {
    from: { height: `0%` },
    to: { height: `100%` },
  })

  if (projects.length === 0) {
    return (
      <Layout renderVideo={false}>
        <Container>
          <Styled.p>Please add projects to this page in the content/projects folder</Styled.p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout
      sx={{
        display: `grid`,
        gridTemplateColumns: `repeat(auto-fit, minmax(380px, 1fr))`,
        width: `100%`,
      }}
      renderVideo
    >
      {trail.map((style, index) => (
        <ProjectItem style={style} node={projects[index]} key={projects[index].slug} />
      ))}
    </Layout>
  )
}

export default Projects
